import React from 'react'
import './howitworks.scss'
import Arrows from '../../content/assets/animated_arrows.inline.svg'
// import TweeningCounter from '../utils/tweening-counter'
// import InitCounters from '../utils/init_counters'
import Helmet from "react-helmet"

class HowItWorks extends React.Component {
  componentDidMount() {
    const TweeningCounter = window.TweeningCounter;
   
    window.raf = (function(){
      return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function(callback){
          window.setTimeout(callback, 1000 / 60);
        };
    })();

    function random(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    var tweeningCounters = [
      new TweeningCounter().duration(3000).onEnd(loop),
      new TweeningCounter().duration(3000).onEnd(loop),
      new TweeningCounter().duration(3000).onEnd(loop)
    ];


    var wrapper1 = document.querySelector('.dist-holder');
    var wrapper2 = document.querySelector('.pow-holder');
    var wrapper3 = document.querySelector('.speed-holder');

      wrapper1.appendChild(tweeningCounters[0].el);
      wrapper2.appendChild(tweeningCounters[1].el);
      wrapper3.appendChild(tweeningCounters[2].el);

    // Run the tweening counter towards a random int between 0 and 50,
    // with a delay of between 0.5s and 2s between each run.

    function loop(start, delay) {
      var now = Date.now();
      start = start || Date.now();
      delay = delay || random(500, 2000);
      if (now - start >= delay) {
        start = null;
        return run(this);
      }
      window.raf(loop.bind(this, start, delay));
    }

    function run(tweeningCounter) {
        tweeningCounter
        .to(random(0, 50))
        .start();
    }

    tweeningCounters.forEach(run);
  }

  render() {
    return(
      <div className="how-it-works">
      <Helmet>
          {/* <script src='https://rawgithub.com/tanem/tweening-counter/master/standalone/tweening-counter.min.js' type="text/javascript" /> */}
          {/* <script src='/src/utils/init_counters.js' type="text/javascript" /> */}
      </Helmet>
        <div className="heading-one">
          <h2>HOW IT WORKS</h2>
        </div>
        <div className="center">
            <div className="outliner">
              <span>The device measures current, voltage, speed, temperature and other parameters of your electric transportation device, then sends the data via wireless connection to your smartphone.</span>
            </div>
          <div className="composition">
            <div className="phone">
              <div className="container">
                <div className="gauge-b"></div>
                <div className="gauge-c"></div>
                <div className="gauge-d"></div>
                <div className="gauge-data">
                  <div className="dist-holder"></div>
                  <div className="pow-holder"></div>
                  <div className="speed-holder"></div>
                  <div className="batt"></div>
                </div>
              </div>
            </div>

            <div className="waves">
              <div className="bluetooth"></div>
              <div className="wave-circle first"></div>
              <div className="wave-circle second"></div>
              <div className="wave-circle third"></div>	
              <div className="wave-circle fourth"></div>
              <div className="wave-circle fifth"></div>
              <div className="wave-circle sixth"></div>
              <div className="wave-circle seventh"></div>
            </div>

            <div className="bike">
              <div className="part frame">
                <div className="bar left-top"></div>
                <div className="bar left-bottom"></div>
                <div className="bar left"></div>
                <div className="bar top"></div>
                <div className="bar rear"></div>
                <div className="bar rear2"></div>
                <div className="bar rear3"></div>
                <div className="bar bottom"></div>
                <div className="bar right"></div>
                <div className="controller"></div>
                <div className="powerwatcher-device"></div>
                <div className="batteryx"></div>
              </div>
              <div className="part sadle">
                <div className="sit-here"></div>
                <div className="sadlepen"></div>
              </div>
              <div className="part handlebar">
                <div className="stem"></div>
                <div className="connector"></div>
                <div className="prehandle"></div>
                <div className="handle"></div>
              </div>
              <div className="part wheel thick"></div>
              <div className="part wheel left">
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
              </div>
              <div className="part wheel right">
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
              </div>
              <div className="part axis left"></div>
              <div className="part axis right"></div>
              <Arrows/>
              
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HowItWorks