import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Teaser from '../components/Teaser'
import HowItWorks from '../components/HowItWorks'
import MainFeatures from '../components/MainFeatures'
import PhoneApp from '../components/PhoneApp'
import PowerMetrics from '../components/PowerMetrics'
import Articles from '../components/Articles'
import Videos from '../components/Videos'
import Testimonials from '../components/Testimonials'
import CTA from '../components/CTA'


class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteSlogan = data.site.siteMetadata.slogan
    const headerGhostBg = `url(${data.fileName.childImageSharp.original.src})`

    return (
      <Layout location={this.props.location} title={siteTitle} slogan={siteSlogan} headerGhostBg={headerGhostBg} >
        <SEO title="Smart Digital Dashboard for Electric Vehicles" />
        <Teaser></Teaser>
        <HowItWorks></HowItWorks>
        <MainFeatures></MainFeatures>
        <PhoneApp></PhoneApp>
        <PowerMetrics></PowerMetrics>
        <Articles></Articles>
        <Videos></Videos>
        <Testimonials></Testimonials>
        <CTA></CTA>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        slogan
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
    fileName: file(relativePath: {eq: "green.jpg"}) {
      childImageSharp {
        original {
          src
        }
      }
    }
  }
`
