import React from 'react'

import './videos.scss'

const Videos = () => {
  return (
    <div className="video">
      <div className="heading-one">
        <h2>POWERWATCHER IN ACTION</h2>
      </div>
      <div className="center">
        <div className="row">
          <div className="video-holder">
            <h3>App demo on the phone</h3>
            <div className="video-block">
              <iframe
                width="480"
                height="360"
                src="https://www.youtube.com/embed/FS2VZUdwZJo"
                srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/FS2VZUdwZJo?autoplay=1><img src=https://img.youtube.com/vi/FS2VZUdwZJo/hqdefault.jpg alt='PowerWatcher on the Phone'><span>▶</span></a>"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="PowerWatcher on the Phone"
              ></iframe>
            </div>
          </div>
          <div className="video-holder">
            <h3>Device demo on the e-bike</h3>
            <div className="video-block">
              <iframe
                width="480"
                height="360"
                src="https://www.youtube.com/embed/l6Z8-xc7d68"
                srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/l6Z8-xc7d68?autoplay=1><img src=https://img.youtube.com/vi/l6Z8-xc7d68/hqdefault.jpg alt='Demo of the device on the e-bike'><span>▶</span></a>"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Demo of the device on the e-bike"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Videos