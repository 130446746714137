import React from 'react'
import { Waypoint } from 'react-waypoint';
import  CountUp from 'react-countup';

import './powermetrics.scss'

class PowerMetrics extends React.Component {
  constructor(props) {
    super(props);
    this._handleWaypointEnter = this._handleWaypointEnter.bind(this); // Bind for appropriate 'this' context
    this._handleWaypointLeave = this._handleWaypointLeave.bind(this); // Bind for appropriate 'this' context
    this._handleWaypoint = this._handleWaypoint.bind(this); // Bind for appropriate 'this' context
  }

  componentDidMount() {
    
  }

  state = {
    didViewCountUp: false
  };
  
  _handleWaypoint = function(up) {
    const section = document.querySelector('.power-metrics');
    const elements = section.querySelectorAll('.fade');
    elements.forEach(function(element) {
      if(up)
        element.classList.add("show");
      else
        element.classList.remove("show");
    });
    this.setState({didViewCountUp: up});
  }

  _handleWaypointEnter = function() { this._handleWaypoint(true); }
  _handleWaypointLeave = function() { this._handleWaypoint(false); }

  render(){
    return(
      <div className="power-metrics" data-appear-top-offset="-400">
        <div className="heading-one">
          <h2>OUTSTANDING CHARACTERISTICS</h2>
        </div>
        <Waypoint
          scrollableAncestor='window'
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
          topOffset='-380px'
        />
        <div className="center">
          <div className="row">
            <div className="power-metrics-itm fade">
              <span>POWER SUPPLY</span>
              <div className="metrics">
                <div className="count">
                <CountUp start={0} end={this.state.didViewCountUp ? 9 : 0}
                   duration={3} ref={countUp => { this.myCountUp = countUp; }}>
                   {({ countUpRef }) => (
                    <div ref={countUpRef}></div>
                  )}   
                </CountUp> 
                </div>
                <div>-</div>
                <div className="count1">
                <CountUp start={0} end={this.state.didViewCountUp ? 600 : 0}
                   duration={3} ref={countUp => { this.myCountUp = countUp; }}>
                   {({ countUpRef }) => (
                    <div ref={countUpRef}></div>
                  )}   
                </CountUp> 
                </div>
                <span>VOLT</span>
              </div>
            </div>
            <div className="power-metrics-itm fade">
              <span>CURRENT</span>
              <div className="metrics">
                <div>0</div>
                <div>-</div>
                <div className="count2">
                  <CountUp start={0} end={this.state.didViewCountUp ? 1000 : 0}
                    duration={3} ref={countUp => { this.myCountUp = countUp; }}>
                    {({ countUpRef }) => (
                      <div ref={countUpRef}></div>
                    )}   
                  </CountUp> 
                </div>
                <span>AMPERES</span>
              </div>
            </div>
            <div className="power-metrics-itm fade">
              <span>SPEED</span>
              <div className="metrics">
                <div>0</div>
                <div>-</div>
                <div className="count3">
                  <CountUp start={0} end={this.state.didViewCountUp ? 200 : 0}
                    duration={3} ref={countUp => { this.myCountUp = countUp; }}>
                    {({ countUpRef }) => (
                      <div ref={countUpRef}></div>
                    )}   
                  </CountUp> 
                </div>
                <span>KM/H</span>
              </div>
            </div>
          </div>
          <div className="row">
            <ul className="rolldown-list" id="myList">
              <li>THE DEVICE ALSO MONITORS THE FOLLOWING PARAMETERS</li>
              <li>AMPER/HOURS SPENT | TOTAL AMPER/HOURS</li>
              <li>WATT/HOURS SPENT | TOTAL WATT/HOURS</li>
              <li>AMPER/HOUR RECUPERATION | WATT/HOUR RECUPERATION</li>
              <li>CHARGE LEVEL</li>
              <li>CYCLE COUNT</li>
              <li>MIN VOLTAGE | MAX VOLTAGE</li>
              <li>MAX CURRENT</li>
              <li>MAX SPEED | AVERAGE SPEED</li>
              <li>TEMPERATURE</li>
              <li>DISTANCE</li>
            </ul> 
            <div className="naked-powerwatcher"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default PowerMetrics