import { Link } from 'gatsby'
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import kebabCase from "lodash/kebabCase"

import './articles.scss'

const Articles = () => {
  const data = useStaticQuery(graphql`
  query ArticlesQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC}, limit:3 ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            author
            featuredImage {
              childImageSharp{
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`)
const posts = data.allMarkdownRemark.edges


  return (
    <div className="articles">
      <div className="heading-two">
        <span className="text-title">REDCOMMENDED READING</span>
        <h2><mark>FEATURED</mark>ARTICLES</h2>
        <div className="underline"><div></div></div>
      </div>
      <div className="center">
        <div className="grid-row">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const tags = node.frontmatter.tags
          return (
            <figure key={node.fields.slug} className="articles-item">
              <Link style={{ boxShadow: `none` }} to={node.fields.slug} className='tab'>
                <div className="effect effect-twelve">
                  <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" alt="" className="img-fluid" imgStyle={{width: '110%'}}></Img>
                  <div className="tab-text">
                    <h2>{title}</h2>
                    <p>Continue reading this article →</p>
                  </div>
                </div>
              </Link>
              <figcaption>
                <div className="mdl-card mdl-card__supporting-text">
                  <div className="card-text"> 
                    <span className="quote"
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                      >
                    </span>
                    <div className='blogpost-author'> 
                      Author:
                      <span>{node.frontmatter.author}</span>
                      
                    </div>
                    <div className='blogpost-tags'> 
                      {tags.map(tag => {
                        return(
                          <Link key={tag} to={`/tags/${kebabCase(tag)}/`} className="badge">
                            {tag} 
                          </Link>
                          // <span className="badge">{tag}</span>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </figcaption>
            </figure>
          )
        })}
          
            
           
         

        </div>
      </div>
    </div>
  )
}

export default Articles
