import React from 'react'
import Android from '../../content/assets/robot.svg'
import Bluetooth from '../../content/assets/bluetooth.svg'
import Plug from '../../content/assets/plug.svg'
import Globe from '../../content/assets/globe.svg'

import './mainfeatures.scss'

class MainFeatures extends React.Component {
  render (){
    return(
      <div className="features">
				<div className="heading-one">
					<h2>MAIN FEATURES</h2>
				</div>
				<div className="center">
					<div className="row">
						<div className="features-item">
							<div className="icon">
								<a href="https://powerwatcher.net">
									<i className="fa fa-bluetooth"></i>
									<img src={Android} alt="android platform"></img>
								</a>
							</div>
							<h3>ANDROID PLATFORM SUPPORT</h3>
							<p>The device's mobile app runs on one of the most popular systems in the world - android. Just install the app, connect the device and you're all set. Wherever you are you're ready to use PowerWatcher.</p>
						</div>
						<div className="features-item">
							<div className="icon">
								<a href="https://powerwatcher.net">
									<i className="fa fa-bluetooth"></i>
									<img src={Bluetooth} alt="bluetooth connectivity"></img>
								</a>
							</div>
							<h3>BLUETOOTH 2.0 ENABLED</h3>
							<p>Bluetooth is an international open standard for device communication. After you pair a device, PowerWatcher automatically sends measured data to your smartphone.</p>
						</div>
						<div className="features-item">
							<div className="icon">
								<a href="https://powerwatcher.net">
									<i className="fa fa-bolt"></i>
									<img src={Plug} alt="wide power supply"></img>
								</a>
							</div>
							<h3>WIDE POWER SUPPLY RANGE</h3>
							<p>The device handles ultra-wide ranges of input currents (24…200V DC), which guarantees efficient and safe operation even in worst possible conditions.</p>
						</div>
						<div className="features-item">
							<div className="icon">
								<a href="https://powerwatcher.net">
									<i className="fa fa-globe"></i>
									<img src={Globe} alt="available for all devices"></img>
								</a>
							</div>
							<h3>WORKS WITH ANY ELECTRIC VEHICLE</h3>
							<p>Whether it's an electric scooter, a bicycle, or a car, PowerWatcher will work with any e-transport device providing you with accurate and up-to-date information.</p>
						</div>
					</div>
				</div>
			</div>
    )
  }
};

export default MainFeatures