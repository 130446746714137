import React from "react"
import './teaser.scss'
import '../styles/battery.scss'
import battery from '../utils/battery.1'
import { OutboundLink } from 'gatsby-plugin-google-analytics'


class Teaser extends React.Component {

  intervalID = 0;
  
  componentDidMount() {
    this.intervalID = battery();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
  }

  render (){
    return (
      <React.Fragment>
        <div className="teaser-holder">
          <div className="teaser">
            <div className="powermeter">
              <div id="battery" className="battery"></div>
              <div id="charge" className="charge"></div>
            </div>
            <div className="heading-holder">
              <h1 className="tsr-hero">Smart Energy Monitoring Device</h1>
              <span className="tsr-text">POWER WATCHER</span>
              <hr/>
              <span className="caption">Collect various data from your electric transportation device and display it in an easy-to-digest manner on your smartphone.</span>
            </div>
            <div className="zone">
              <OutboundLink className="primary-btn" href="https://shop.emkit.net/shop/product/power-watcher-6">ORDER NOW</OutboundLink>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Teaser
