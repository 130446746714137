import React from 'react'
import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css';

import Yuriy from '../../content/assets/yuriy.webp';
import Dmitriy from '../../content/assets/dmitr.webp';
import Rostislav from '../../content/assets/rostislav.webp';
import facebook from '../../content/assets/social_icons/facebook.svg';
import youtube from '../../content/assets/social_icons/youtube.svg';
import twitter from '../../content/assets/social_icons/twitter.svg';

import './testimonials.scss'

class Testimonials  extends React.Component {
  state = {
    width: 601, // or your default width here
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.handleWindowSizeChange() // Set width
      window.addEventListener('resize', this.handleWindowSizeChange)
    }
  }
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleWindowSizeChange)
    }
  }
  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth,
    })
  }
  render(){
    const { width } = this.state
    const isMobileOrTablet = width <= 985
    const visibleSlides = isMobileOrTablet ? 1 : 2

    return (
      <div className="customers">
        <div className="heading-two">
          <span className="text-title">TESTIMONIALS</span>
            <h2>WHAT OUR CUSTOMERS<mark>SAY</mark></h2>
            <div className="underline"></div>
        </div>
        <div className="center">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={62}
            totalSlides={3}
            isPlaying={true}
            visibleSlides={visibleSlides}
          >
            <Slider trayTag='div'>
              <Slide tag='div' index={0}>
                <div className="testimonial-container fade-out-text">
                  <a className="testimonial__follow" align="center" href="https://www.facebook.com/people/%D0%AE%D1%80%D0%B8%D0%B9-%D0%90%D0%BD%D0%B4%D1%80%D0%B5%D0%BD%D0%BA%D0%BE/100009633408463">Follow</a>
                  <div className="testimonial__img-container">
                    <img className="testimonial__profile-img" src={Yuriy} alt="Yuriy from Kharkiv"/>
                  </div>
                  <div className="testimonial__background-img"></div>
                  <p className="testimonial__name">
                    <a className="testimonial__icon" href='https://www.facebook.com/people/%D0%AE%D1%80%D0%B8%D0%B9-%D0%90%D0%BD%D0%B4%D1%80%D0%B5%D0%BD%D0%BA%D0%BE/100009633408463'>
                      <img src={facebook} alt="facebook icon"></img>
                    </a>
                    Yuriy, Kharkiv
                  </p>
                  <p className="testimonial__bio">I use this device on my bike all the time. You can see it in the video on the site)). I will say that PowerWatcher is a very handy thing that eliminates the need of bicycle speedometer and wattmeter, and allows you to get rid of all those unnecessary wires.</p>
                  <div className="fader"></div>
                </div>
              </Slide>
              <Slide tag='div' index={1}>
                <div className="testimonial-container fade-out-text">
                  <a className="testimonial__follow" align="center" href="https://www.facebook.com/people/%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9-%D0%93%D0%B8%D0%B4%D1%83%D0%BB%D1%8F%D0%BD/100018577359148">Follow</a>
                  <div className="testimonial__img-container">
                    <img className="testimonial__profile-img" src={Dmitriy} alt="Dmitriy from Kirovograd"/>
                  </div>
                  <div className="testimonial__background-img"></div>
                  <p className="testimonial__name">
                    <a className="testimonial__icon" href='https://www.facebook.com/people/%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9-%D0%93%D0%B8%D0%B4%D1%83%D0%BB%D1%8F%D0%BD/100018577359148'>
                      <img src={facebook} alt="facebook icon"></img>
                    </a>
                    <a className="testimonial__icon" href='https://www.youtube.com/user/babac1985/videos'>
                      <img src={youtube} alt="youtube icon"></img>
                    </a>
                    Dmitry, Kirovograd
                  </p>
                  <p className="testimonial__bio">Very decent device in terms of price and functionality. Many thanks to the author for his immediate support and information. Totally Recommend!</p>
                  <div className="fader"></div>
                </div>
              </Slide>
              <Slide tag='div' index={2}>
                <div className="testimonial-container fade-out-text">
                  <p className="testimonial__follow" align="center">Follow</p>
                  <div className="testimonial__img-container">
                    <img className="testimonial__profile-img" src={Rostislav} alt="Rostislav from Kiev"/>
                  </div>
                  <div className="testimonial__background-img"></div>
                  <p className="testimonial__name">
                    <a className="testimonial__icon" href='https://powerwatcher.net'>
                      <img src={facebook} alt="facebook icon"></img>
                    </a>
                    <a className="testimonial__icon" href='https://powerwatcher.net'>
                      <img src={youtube} alt="youtube icon"></img>
                    </a>
                    <a className="testimonial__icon" href='https://powerwatcher.net'>
                      <img src={twitter} alt="twitter icon"></img>
                    </a>
                    Rostislav, Kiev
                  </p>
                  <p className="testimonial__bio">Excellent device, easy to install, replaces the wattmeter, speedometer. For those who don't have transport with a telemetry screen, very worthy alternative. Frequent firmware updates, new features, excellent support.</p>
                  <div className="fader"></div>
                </div>
              </Slide>
            </Slider>
            <div className="carousel__dot-group">
              <Dot aria-label="slide number 1" slide="0" />
              <Dot aria-label="slide number 2" slide="1"/>
              <Dot aria-label="slide number 3" slide="2"/>
            </div>
          </CarouselProvider>
        </div>
      </div>
    )
  }
}


export default Testimonials