import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import './cta.scss'

const CTA = () => {
  return(
    <div className="cta">
      <div className="center">
        <div className="cta-heading-holder">
          <h2>START MONITORING YOUR E-VEHICLE</h2>
          <span>PowerWatcher</span>
        </div>
        <div className="final-cta-button">
          <OutboundLink className="primary-btn" href="https://shop.emkit.net/shop/product/power-watcher-6">ORDER NOW</OutboundLink>
        </div>
      </div>
    </div>
  )
}
export default CTA