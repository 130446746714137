import React from 'react'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Screen1 from '../../content/assets/screenshots/screen1.webp';
import Screen2 from '../../content/assets/screenshots/screen2.webp';
import Screen3 from '../../content/assets/screenshots/screen3.webp';
import Screen4 from '../../content/assets/screenshots/screen4.webp';
import Screen5 from '../../content/assets/screenshots/screen5.webp';
import Screen6 from '../../content/assets/screenshots/screen6.webp';
import Screen7 from '../../content/assets/screenshots/screen7.webp';


import './phoneapp.scss'

class PhoneApp extends React.Component {
  render(){
    return(
      <div className="phone-app">
        <div className="heading-two">
          <span className="text-title">ALWAYS STAY CONNECTED WITH YOUR ELECTRIC VEHICLE</span>
          <h2><mark>BLUETOOTH</mark>PHONE APP</h2>
          <div className="underline"><div></div></div>
        </div>
        <div className="center">
          <p>The mobile app is essential to the PowerWatcher experience. It lets you instantly see all the major operational parameters of your electric vehicle, such as voltage, current, power consumption, velocity, temperature, battery energy consumption etc. Moreover, based on these basic parameters application calculates many additional ones, like recuperation percentage, min/max/avarage values of voltage, current and stores them in memory. Regular firmware updates will let you gain access to all of the cool new features that will appear in the future versions of PowerWatcher.</p>
          <div className="mobile-holder">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={3}
            isPlaying={true}
          >
            <Slider>
              <Slide index={0}><img src={Screen1} alt="PowerWatcher main screen"></img></Slide>
              <Slide index={1}><img src={Screen2} alt="PowerWatcher parameters screen"></img></Slide>
              <Slide index={2}><img src={Screen3} alt="PowerWatcher main section screen"></img></Slide>
              <Slide index={3}><img src={Screen4} alt="PowerWatcher top section screen"></img></Slide>
              <Slide index={4}><img src={Screen5} alt="PowerWatcher left section screen"></img></Slide>
              <Slide index={5}><img src={Screen6} alt="PowerWatcher right section screen"></img></Slide>
              <Slide index={6}><img src={Screen7} alt="PowerWatcher bottom sectionscreen"></img></Slide>
            </Slider>
          </CarouselProvider>
          </div>
        </div>
      </div>
    )
  }
}

export default PhoneApp